import axios from "axios";
const API_URL = process.env.VUE_APP_URL_API;

class InsumosService {
  
    async getAllInsumosByCompany() {
        return await axios.get(`${API_URL}/Insumos/getAllInsumosByCompany`);   
    }

    async checkFilesInsumos(nCodOp) {
        let params = {
            NCodOp: nCodOp,
        };
        return await axios.get(`${API_URL}/Insumos/verifyNCodOp/`, {params});    
    }

    async checkHasInsumosPasso3(nCodOp) {
        let params = {
            NCodOp: nCodOp,
        };
        return await axios.get(`${API_URL}/Insumos/checkHasInsumosPasso3/`, {params});    
    }

    async getInsumos(nCodOp, passo) {
        return await axios.get(`${API_URL}/Insumos/getInsumos/`, {
            params: {
                NCodOp: nCodOp,
                Passo: passo,
            }
        }); 
    }

    getItensModal(i050) {
        return axios.get(`${API_URL}/Insumos/GetItensModal/`, {
          params: {
            CodCta: i050.codCta,
            NCodOp: i050.nCodOp,
          }
        }); 
      }

    async saveModalI250(i250sToRemove, nCodOp, codCta) {
        return axios.post(`${API_URL}/Insumos/saveModalI250`, {
            I250sToRemove: i250sToRemove,
            CodCta: codCta,
            NCodOp: nCodOp,
        });
    }

    async getCreditosUtilizados(nCodOp){
        return axios.get(`${API_URL}/Insumos/getCreditosUtilizados/`, {
            params: {
              NCodOp: nCodOp,
            }
          }); 
    }

    exportRelatorio(nCodOp, reduzido) {
        console.log(reduzido);
        return axios.post(`${API_URL}/Insumos/exportRelatorioInsumos/`, {NCodOp: nCodOp, Reduzido:reduzido},{responseType: "blob",});   
    }

    exportPDF(nCodOp, reduzido) {
        console.log(reduzido);
        return axios.post(`${API_URL}/Insumos/exportPDF/`, {NCodOp: nCodOp, Reduzido:reduzido},{responseType: "blob",});   
    }

    exportRelatorioMensal(nCodOp) {
        return axios.post(`${API_URL}/Insumos/exportRelatorioMensalInsumos/`, {NCodOp: nCodOp},{responseType: "blob",});   
    }

    exportRelatorioReduzido(nCodOp) {
        return axios.get(`${API_URL}/Insumos/exportRelatorioReduzidoInsumos/`, {NCodOp: nCodOp}); 
    }

    async removeInsumo(nCodOp, codCta) {
        return axios.post(`${API_URL}/Insumos/removeInsumoSelecionado`, {
            NCodOp: nCodOp,
            CodCta: codCta,
        });
    }

    removeAllInsumos(nCodOp) {
        return axios.post(`${API_URL}/Insumos/removeAllInsumos`, {
            NCodOp: nCodOp
        });
    }           

    async getCliente(nCodOp) {
        return await axios.get(`${API_URL}/Insumos/getClienteName`,{
            params:{
                NCodOp: nCodOp,
            }
        });   
    }

    async updateResultInsumos(nCodOp) {
        return await axios.get(`${API_URL}/Insumos/calcularInsumos/`, {
            params: {
                NCodOp: nCodOp
            }
        });
    }

    async getResultInsumos(nCodOp) {
        return await axios.get(`${API_URL}/Insumos/getResultInsumos/`, {
            params: {
                NCodOp: nCodOp
            }
        });
    }
    
}

export default new InsumosService();
